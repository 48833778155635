import React from "react"
import Layout from "../layouts/en"
import { Stack } from "@mui/material"
import LocalizedLink from "../components/LocalizedLink"

const NotFoundPage = props => (
  <Layout location={props.location} title="404: Not found">
    <Stack mx={2} justifyContent="center" alignItems="center" minHeight="60vh">
      <h1>NOT FOUND</h1>
      <p>
        You just hit a route that doesn&#39;t exist...{" "}
        <LocalizedLink to="/">Go back here.</LocalizedLink>
      </p>
    </Stack>
  </Layout>
)

export default NotFoundPage
